export const COLLECTION_ACCOUNT = "account"
export const COLLECTION_CONTENTS = "contents"

export const ROUTE_ID = "/:id";
export const ROUTE_LIST = "/list"
export const ROUTE_EDIT = "/edit"
export const ROUTE_CONTENTS = "/contents"
export const ROUTE_CONTENTS_LIST_VIEW = ROUTE_CONTENTS + ROUTE_LIST
export const ROUTE_CONTENTS_EDIT_VIEW = ROUTE_CONTENTS + ROUTE_EDIT

export const NAME_ID="name"
export const NUMBER_ID="number"
export const BIRTH_ID="birth"
export const LOGIN_ID="login"