export const STYLE = {
    LOGIN_FIELD_WIDTH: 400,
    LOGIN_FIELD_HEIGHT: 50,
    WIDTH_LABEL: 200
}

export const LABEL = {
    NAME: "이름",
    NUMBER: "사번(7자리)",
    BIRTH: "생년월일(6자리)",
    LOGIN_TITLE:"로그인하세요",
    LOGIN_SUB_TITLE:"1년 후 나에게 쓰는 편지",
    LOGIN:"로그인"
}
export const MSG ={
    LOGIN_ERR:"이름, 사번, 생년월일을 확인해주세요.",
    HINT_NAME:"이름을 입력하세요.",
    HINT_NUMBER:"사번을 입력하세요.(7자리)",
    HINT_BIRTH:"생년월일 입력하세요.(6자리)",
    DOWNLOAD_ERR:"Chrome 브라우져를 통해 다운로드 가능합니다.",
    DOWNLOAD:"잠시 후 다운로드가 시작됩니다.",
    DOWNLOAD_GUIDE:"*아이폰 영상 다운로드 오류 발생 시 크롬 브라우저(앱)를 통해 접속 후 다운로드 시도해 보세요!"
    
}
